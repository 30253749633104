import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Api, {httpErrorHandler} from "../../../services/server/api";
import {CreateUser} from "../../../services/utils/types/types";
import {Box, LinearProgress} from "@mui/material";
import CreatePerson from "../../../components/create-person";
import moment from "moment";


export default function EditPerson() {
    const params= useParams()
 
const [anchorEl, setAnchorEl] = React.useState(null);
const nav= useNavigate()
const [isLoading, setLoading] = React.useState(true);
const open = Boolean(anchorEl);

const [data, setData] = useState < any > ();
const api = new Api();
const navigate = useNavigate();


  const fetchUser =() => {
    setLoading(true);
    api.getPerson(params.id)
        .then((response) => {
          
            setData(response.data.data[0]);
            setLoading(false);
            
        })
        .catch(err => {
            setData([]);
            setLoading(false);
            throw err;
        })
        .catch(httpErrorHandler);
};
useEffect(() =>{
  fetchUser()
},[])
const initialValues: CreateUser = {
  name:  data?.name ,
  gender: data?.gender ,
  yearOfBirth: data?.yearOfBirth ,
  typeOfDocument:data?.typeOfDocument ,
  emailAddress: data?.emailAddress ,
  // id: "",
  callingNum: data?.callingNum ,
  fileNo: data?.fileNo ,
  serielNo:data?.serielNo ,
  immi13Status: data?.immi13Status ,
  applicationStatus: data?.applicationStatus ,
  notes: data?.notes ,
  education: { LEVEL:data?.education?.LEVEL , FIELD: data?.education?.FIELD , UNIVERSITY: data?.education?.UNIVERSITY },
  unStatus: data?.unStatus ,
  unhrcCardExpiryDate: data?.unhrcCardExpiryDate ,
  mobileNo: data?.mobileNo ,
  address: { DISTRICT: data?.address?.DISTRICT , STATE: data?.address?.STATE , DETAIL_ADDRESS: data?.address?.DETAIL_ADDRESS , },
  employment: { SALARY: data?.employment?.SALARY , POSITION: data?.employment?.POSITION ,DETAILS_OF_EMPLOYER: data?.employment?.DETAILS_OF_EMPLOYER ,},
  typeOfPass: data?.typeOfPass ,
  // yearOfChange: "",
  remarks: data?.remarks ,
    dateOfBirth:data?.dateOfBirth,
    relationship:data?.relationship
};
console.log(data?.dateOfBirth)
const onSubmit = (values: CreateUser) => {
    let dob= moment(new Date(values.dateOfBirth)).format('DD/MM/YYYY');
    values.yearOfBirth = moment(new Date(values.dateOfBirth)).format('YYYY');
    values.dateOfBirth=dob;
        setLoading(true)
        api.UpdatePerson(values, params.id)
  .catch(httpErrorHandler => {
    setLoading(false)
    navigate(-1);
  }).then(() => {
    // TODO show success message
    setLoading(false);
    navigate(-1);
    
})
};
return (
  <Box>

  {isLoading && <LinearProgress color="secondary"/>}

    {data? <CreatePerson initialValues={initialValues} onSubmit={onSubmit} label={"Edit Person"}/> : ""}
  </Box>
  )
}
