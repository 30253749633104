import * as yup from "yup";
import {  Form, Formik } from "formik";
import { Box, Button, createTheme, Grid, Paper, TextField, ThemeProvider, Typography } from "@mui/material";
import TextInput from "./Input/text-input";
import TextSelect from "./Input/text-select";
import DateInput from "./Input/date-input";
import { newMember } from "../services/utils/types/types";
import dayjs from "dayjs";
import {applicationStatus, imm13Status, states} from "../services/utils/helper";


    export default function CreateMember({initialValues, onSubmit, label}:{initialValues:newMember, label:string,  onSubmit:(value:( newMember )) => void}) {

        const theme = createTheme({
        palette: {
          primary: {
            main: "#8a009b",
          },
        },
      });
        const validationSchema = yup.object({
          name:yup.string().required("required"),
          gender:yup.string().required("required"),
          relationship:yup.string().required("required"),
            emailAddress:yup.string().email(`Invalid email format`).required(`required`),
            dateOfBirth:yup.string().required("required"),
            typeOfDocument:yup.string().required("required"),
            mobileNo:yup.string().required("required")

        })
          
  return (
    <ThemeProvider theme={theme}>
    <Typography
      component="h1"
      variant="h5"
      noWrap
      color={"primary.main"}
      sx={{ my: 4, mx: 4 }}
    >
           {label}
    </Typography>
    <Box
      sx={{
        my: 4,
        mx: 4,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 200px)",
      }}
    >
      <Paper 
      sx={{
          p: 4,
        }}
        elevation={2}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Box>
              <Form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2} columnSpacing={2}>

                  <Grid item xs={12} md={6}>
                    <TextInput label="Full Name" name="name"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextSelect labelInput="Gender" name="gender" option={["male", "female"]}   />
                  </Grid>
                 <Grid item xs={12} md={6}>
                 <TextSelect labelInput="Relationship type" name="relationship" option={["FATHER", "MOTHER", "SON", "DAUGHTER", "MAIN APPLICANT", "SPOUSE"]}   />
                 </Grid>

                  <Grid item xs={12} md={6}>
                  <TextSelect
                          labelInput="Application status"
                          name="applicationStatus"
                          option={applicationStatus}
                        />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Email Address" name="emailAddress"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Calling Num" name="callingNum"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Mobile No" name="mobileNo" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  { <DateInput label="Date of Birth" name="dateOfBirth"  onChange={(value) => setFieldValue("dateOfBirth",value)}  />}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Un Status(Unhcr Card No)"
                      name="unStatus"
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Unhcr Card Expiry Date"
                      name="unhrcCardExpiryDate"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="File No" name="fileNo"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <TextSelect labelInput="IMM13 Status" name="immi13Status" option={imm13Status}  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Seriel No" name="serielNo" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Type Of Pass" name="typeOfPass"  />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Notes" name="notes" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Note(s)/Remark" name="remarks" />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput label="Document" name="typeOfDocument" />
                  </Grid>
                 
                  <Grid item xs={12}>
                    <Typography color={"primary.main"} variant="h6">
                      Address
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="District"
                      name="address.DISTRICT"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextSelect labelInput="State" name="address.STATE" option={states}/>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Detail Address"
                      name="address.DETAIL_ADDRESS"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color={"primary.main"} variant="h6">
                      Education
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="ُEducation Level"
                      name="education.LEVEL"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="ُEducation Field"
                      name="education.FIELD"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="ُEducation University"
                      name="education.UNIVERSITY"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color={"primary.main"} variant="h6">
                      Employment
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Employment Salary"
                      name="employment.SALARY"
                      type="number"
                      
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Employment  Position"
                      name="employment.POSITION"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Employment Details Of Employer"
                      name="employment.DETAILS_OF_EMPLOYER"
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} md={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Box>
          )}
        </Formik>
      </Paper>
    </Box>
  </ThemeProvider>
  )
}
