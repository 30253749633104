import * as React from 'react';
import {Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, Container,
    createTheme, CssBaseline, FormControlLabel, Grid,
    Link,
    Paper, TextField, ThemeProvider, Typography } from '@mui/material';
import Api, {httpErrorHandler} from "../../services/server/api";
import {useAuth} from "../../provider/authProvider";
import {createSearchParams, useNavigate} from "react-router-dom";
import {USERPROFILE} from "../../constants/routes";

interface LoginProps {

}

const theme = createTheme();

export const Login: React.FC<LoginProps> = (props) => {
    const { setToken } = useAuth();
    const api = new Api();
    let navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        let data = {username: formData.get('username'),password: formData.get('password')};

        api
            .postLogin(data)
            .then((response:any) => {
                if(response && response.data.token){
                    setToken(response.data.token);
                    localStorage.setItem('name',response.data.name);
                    navigate('/view_application');
                    window.location.reload();
                }
            })
            .catch((err) => {
                console.log(err)
                setToken(null);
                navigate("/login", { replace: true });
            })
    };



  return (
      <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="md">
          <Box
              sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh'
              }}
          >
              <Paper sx={{
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
              }} elevation={2} >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  </Avatar>
                  <Typography component="h1" variant="h5">
                      Sign in
                  </Typography>
                  <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                      <TextField
                          margin="normal"
                          required
                          fullWidth
                          id="username"
                          label="Email Address"
                          name="username"
                          autoComplete="email"
                          autoFocus
                      />
                      <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                      />
                      <FormControlLabel
                          control={<Checkbox value="remember" color="primary" />}
                          label="Remember me"
                      />
                      <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                      >
                          Sign In
                      </Button>
                      <Grid container>
                          <Grid item xs>
                              <Link href="#" variant="body2">
                                  Forgot password?
                              </Link>
                          </Grid>
                          <Grid item>
                              <Link href="#" variant="body2">
                                  {"Don't have an account? Sign Up"}
                              </Link>
                          </Grid>
                      </Grid>
                  </Box>
              </Paper>
          </Box>
          </Container>
      </ThemeProvider>
  );
};