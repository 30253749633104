
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import { Typography } from '@mui/material';
export default function TextSelect({name, labelInput, option}:{name:string, labelInput:string, option:string[]}) {
    const [field, meta] = useField(name);


  return (
    <FormControl fullWidth error={meta.touched && !!meta.error}>
    <InputLabel id="demo-simple-select-label">{labelInput}</InputLabel>
    <Select
     fullWidth  label={labelInput}   
      labelId="demo-simple-select-label"

      {...field} 
    >
      {option.map((el, index) =>(
      <MenuItem value={el}>{el}</MenuItem>
      ))}
    </Select>
    <Typography  variant='subtitle2'  color={"error"}>
    {meta.touched && meta.error ? meta.error: ""}
    </Typography>
  </FormControl>
  )
}
