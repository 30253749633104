import * as React from "react";
import { CreateUser } from "../../services/utils/types/types";
import Api, {httpErrorHandler} from "../../services/server/api";
import { createTheme } from "@mui/material";
import CreatePerson from "../../components/create-person";
import moment from "moment";
import {useNavigate} from "react-router";
interface NewApplicationProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: "#8a009b",
    },
  },
});
export const NewApplication: React.FC<NewApplicationProps> = (props) => {
  const initialValues: CreateUser = {
    name: "",
    gender: "",
    yearOfBirth: "",
    typeOfDocument: "",
    emailAddress: "",
    // id: "",
    callingNum: "",
    fileNo: "",
    serielNo: "",
    immi13Status: "",
    applicationStatus: "",
    notes: "",
    education: { LEVEL: "", FIELD: "", UNIVERSITY: "" },
    unStatus: "",
    unhrcCardExpiryDate: "",
    mobileNo: "",
    address: { DISTRICT: "", STATE: "", DETAIL_ADDRESS: "" },
    employment: { SALARY: "", POSITION: "", DETAILS_OF_EMPLOYER: "" },
    typeOfPass: "",
    // yearOfChange: "",
    relationship: "",
    remarks: "",
    dateOfBirth:""
  };
  const api = new Api();
  const navigate = useNavigate();

  const onSubmit = (values: CreateUser) => {
    let dob= moment(new Date(values.dateOfBirth)).format('DD/MM/YYYY');
    values.yearOfBirth = moment(new Date(values.dateOfBirth)).format('YYYY');
    values.dateOfBirth=dob;
    api.addNewApplication(values)
        .catch(httpErrorHandler)
        .then(() => {
          navigate(-1);
        })

  };
 

  return (
    <CreatePerson initialValues={initialValues} onSubmit={onSubmit} label="Personal Details"/>
  );
};
