import {Box, Button, createTheme, Grid, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";
import {map} from "lodash";
import {LabelWithDetails} from "../../../../components/LabelWithDetails";
import * as people from "../../../../services/utils/people.json";
import {getFamilyMembersDetails} from "../../../../services/utils/helper";
import {PassportListComp} from "../../../../components/passportListComp";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {applicationType, userType} from "../../../../services/utils/types/types";
import {Link} from "react-router-dom";
import Details from "../../../../components/details";

interface SpouseDetailsProps {
    application?: applicationType;
}

const theme = createTheme();

export const SpouseDetails: React.FC<SpouseDetailsProps> = (props) => {
    let userList: userType[], user: userType | undefined = {};
    if (props && props.application && props.application.person && props.application.person) {
        userList = props.application.person;
        user = userList.find(person => person.relationship ? person.relationship.toLowerCase() === "spouse" : false);
    }


    let immiList: any = [];
    let passportList: any = [];
    let userID = "";
    if (user && user.id) {
        userID = user.id;
    }
    if (user && user.immi13) {
        immiList = Array.from(user.immi13);
    }
    if (user && user.passport) {
        passportList = Array.from(user.passport);
    }
    const spouseDetails = getFamilyMembersDetails(user);

    console.log(spouseDetails);

    const emptyDom = <div></div>
    const dom = <ThemeProvider theme={theme}>
        <Box
            sx={{
                m: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2
            }}
        >
            <div style={{}}>
                {/* {map(spouseDetails, (i) => (
                    <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                ))} */}
                <Box sx={{flexGrow: 1, paddingLeft: 2}}>
                    <Grid container spacing={2}>
                        <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1}} color="primary">personal
                            details</Typography></Grid>
                        <Details title="RELATIONSHIP TYPE"
                                 children={`${user ? (user.relationship ? user.relationship : "-") : "-"}`}/>
                        <Details title="Full Name" children={`${user ? (user.name ? user.name : "-") : "-"}`}/>
                        <Details title="Calling Num"
                                 children={`${user ? (user.callingNum ? user.callingNum : "-") : "-"}`}/>
                        <Details title="File No." children={`${user ? (user.fileNo ? user.fileNo : "-") : "-"}`}/>
                        <Details title="Gender" children={`${user ? (user.gender ? (user.gender) : "-") : "-"}`}/>
                        <Details title="Year of Birth"
                                 children={`${user ? (user.yearOfBirth ? user.yearOfBirth : "-") : "-"}`}/>
                        <Details title="Age"
                                 children={`${(user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0")}`}/>
                        <Details title="Age Category"
                                 children={`${(user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0")}`}/>
                        <Details title="document"
                                 children={`${user ? (user.typeOfDocument ? user.typeOfDocument : "-") : "-"}`}/>
                        <Details title="IMM13 STATUS"
                                 children={`${user ? (user.immi13Status ? user.immi13Status : "-") : "-"}`}/>
                        <Details title="APPLICANT STATUS"
                                 children={`${user ? user.applicationStatus ? user.applicationStatus : "-" : "-"}`}/>
                        <Details title="Notes" children={`${user ? (user.notes ? user.notes : "-") : "-"}`}/>
                        <Details title="UN STATUS (UNHCR CARD NO.)"
                                 children={`${user ? (user.unStatus ? user.unStatus : "-") : "-"}`}/>
                        <Details title="UNHRC CARD EXPIRY DATE"
                                 children={`${user ? user.unhrcCardExpiryDate ? user.unhrcCardExpiryDate : "-" : "-"}`}/>
                        <Details title="MOBILE NO." children={`${user ? (user.mobileNo ? user.mobileNo : "-") : "-"}`}/>
                        <Details title="EMAIL ADDRESS"
                                 children={`${user ? (user.emailAddress ? user.emailAddress : "-") : "-"}`}/>
                        <Details title="NOTE(S) / REMARK(S)"
                                 children={`${user ? (user?.remarks ? user.remarks : '-') : '-'}`}/>

                        <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                  color="primary">Address</Typography></Grid>
                        <Details title="DISTRICT"
                                 children={`${user ? (user.address ? user.address.DISTRICT ? user.address.DISTRICT : "-" : "-") : "-"}`}/>
                        <Details title="STATE"
                                 children={`${user ? (user.address ? user.address.STATE ? user.address.STATE : '-' : '-') : '-'}`}/>
                        <Details title="Detailed Address"
                                 children={`${user ? (user.address ? user.address.DETAIL_ADDRESS ? user.address.DETAIL_ADDRESS : '-' : '-') : '-'}`}/>
                        <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                  color="primary">Education</Typography></Grid>
                        <Details title="Education FIELD"
                                 children={`${user ? (user.education ? user.education.FIELD ? user.education.FIELD : '-' : '-') : '-'}`}/>
                        <Details title="LEVEL"
                                 children={`${user ? (user.education ? user.education.LEVEL ? user.education.LEVEL : '-' : '-') : '-'}`}/>
                        <Details title="UNIVERSITY"
                                 children={`${user ? (user.education ? user.education.UNIVERSITY ? user.education.UNIVERSITY : '-' : '-') : '-'}`}/>

                        <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                  color="primary">Employment</Typography></Grid>
                        <Details title="DETAILS_OF_EMPLOYER"
                                 children={`${user ? (user.employment ? user.employment.DETAILS_OF_EMPLOYER ? user.employment.DETAILS_OF_EMPLOYER : '-' : '-') : '-'}`}/>
                        <Details title="POSITION"
                                 children={`${user ? (user.employment ? user.employment.POSITION ? user.employment.POSITION : '-' : '-') : '-'}`}/>
                        <Details title="SALARY"
                                 children={`${user ? (user.employment ? user.employment.SALARY ? user.employment.SALARY : '-' : '-') : '-'}`}/>
                    </Grid>
                </Box>
            </div>
            <Link to={`/user_profile/${user?.id}`}>
                <Button variant="contained" sx={{marginLeft: 2}}>edit</Button>
            </Link>

            {/*List of passports*/}
            <PassportListComp passportList={passportList} personID={userID}></PassportListComp>

            {/*List of IMMI13 cards*/}


            <ImmiCardsComp immiCardList={immiList} personID={userID}></ImmiCardsComp>

        </Box>
    </ThemeProvider>


    if (!(user && user.name && user.name.length > 3)) {
        return emptyDom;
    } else {
        return dom;
    }


};