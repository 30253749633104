import * as React from "react";
import {map} from "lodash";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    Avatar,
    Box, Button,
    createTheme, LinearProgress, Paper,
    ThemeProvider
} from "@mui/material";
import {getUserProfileTabs} from "./userProfileTabs";
import Typography from "@mui/material/Typography";
import {RowDetailWithBorder} from "../../components/RowDetailWithBorder";
import Api from "../../services/server/api";
import {useEffect} from "react";
import { applicationType, userType } from "../../services/utils/types/types";
import {PRINT_APPLICATION, USERPROFILE} from "../../constants/routes";
import {createSearchParams, useNavigate, useParams} from "react-router-dom";

const theme = createTheme();

interface UserProfileProps {

}



interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

        
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}


export const UserProfile: React.FC<UserProfileProps> = (props) => {
    let application: applicationType = {
        applicationStatus: "",
        callingNum: "",
        emailAddress: "",
        mobileNo: "",
        name: "",
        person: []
    };
    let navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [applicationData, setApplication] = React.useState(application);
    const queryParameters = new URLSearchParams(window.location.search)
    const appId = queryParameters.get("id");

    const getApplication = (params: any) => {
        setLoading(true);
        const api = new Api();
        api
            .getApplication(params)
            .then((response) => {

                application = response.data[0];
                setApplication(application);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setApplication(application);
                setLoading(false);
            });
    };
    useEffect(() => {
        getApplication({id: appId});
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


let userList: userType[] | null  = applicationData.person;
    // @ts-ignore
    const user: userType = userList?.find(person => person.relationship === "MAIN APPLICANT");
    if (!user) {
        console.log('Not Found!')
    }

    const userDetails = [
        {title: `CALLING NUMBER`, detail: user ? user.callingNum ? user.callingNum : '-' : '-'},
        {title: `PHONE`, detail: user ? user.mobileNo ? user.mobileNo : '-' : '-'},
        {title: `EMAIL`, detail: user ? user.emailAddress ? user.emailAddress : '-' : '-'},
        {title: `APPLICATION STATUS`, detail: user ? user.applicationStatus ? user.applicationStatus : '-' : '-'}
    ]

    const statusOption = [
        {label: `Under Review`, value: 'under_review'},
        {label: `Approved`, value: 'approved'},
    ]

    function handlePrint() {
        if(appId){
            navigate({
                pathname: '/' + PRINT_APPLICATION,
                search: createSearchParams({
                    id: appId
                }).toString()
            });
        }
    }
    
    return (
        <ThemeProvider theme={theme}>

            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{flexGrow: 1, m: 2,}}
            >
                User Profile
            </Typography>
            {isLoading && <LinearProgress color="secondary"/>}

            <Box sx={{display: 'flex', flexDirection:{xs:"column", sm:"row"}}}>
                <Paper sx={{
                    mb: 4,
                    ml: 2,
                    py: 2,
                    px: 1,
                    minWidth: '300px',
                    maxWidth: '300px',
                }} elevation={2}>
                    <div style={{alignItems: 'center', display: 'flex', flexDirection: 'column',}}>
                        <Avatar sx={{m: 1, bgcolor: 'secondary.main', width: 100, height: 100}}>
                        </Avatar>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            sx={{textAlign: "center"}}
                        >
                            {user ? user.name ? user.name : '-' : '-'}
                        </Typography>

                    </div>
                    <div style={{marginTop: '20px', width: '100%', borderTop: '1px solid #c1c0c0',}}>
                        {map(userDetails, i => (
                            <RowDetailWithBorder title={i.title}>{i.detail}</RowDetailWithBorder>
                        ))}
                    </div>
                    {/*<Typography
                        component="h1"
                        variant="subtitle2"
                        color="inherit"
                        sx={{ fontWeight: 600, marginTop: '20px' }}
                    >
                        Application Status
                    </Typography>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{width: '100%', mt: 1}}
                    >
                        {map(statusOption, i => (
                            <MenuItem value={i.value}>{i.label}</MenuItem>

                        ))}
                    </Select>
*/}         <Button sx={{width: '100%', display: 'flex', mt: 2, textAlign: 'center'}} variant="contained" color="primary" onClick={handlePrint}>
                        Generate PDF
                    </Button>
                </Paper>
                <Paper sx={{
                    mb: 4,
                    mx: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                }} elevation={2}>
                    <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                        {map(getUserProfileTabs(applicationData), (i) => (
                            <Tab label={i.text}/>
                        ))}
                    </Tabs>
                    {map(getUserProfileTabs(applicationData), (i, index) => (
                        <TabPanel value={value} index={index}>
                            {i.children}
                        </TabPanel>
                    ))}
                </Paper>
            </Box>
        </ThemeProvider>
    );
};