import { Grid, Typography } from '@mui/material';
import React from 'react'

export default function Details({title, children}:{title:string, children:string}) {
  return (

    <Grid item xs={12} md={6} lg={4} >
        <Typography
                component="h1"
                variant="inherit"
                color="h6"
                sx={{ fontWeight: '700' }}
            >
                {title}
            </Typography>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
            >
                {children}
            </Typography>
        </Grid>

  )
}
